import { Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SharedDataService, Staff } from 'elbuild-ui-lib-core';
import { SubscriptionHeader, SubscriptionHeaderField } from 'ricerca-amministrativa-ui-lib';
import { plainToClass } from 'class-transformer';

@Injectable()
export class LoggedUserInterceptor implements HttpInterceptor {

	loggedUser: Staff;

	constructor(private sharedDataService: SharedDataService) {
		this.sharedDataService.currentLoggedUser.subscribe(user => {
			this.loggedUser = user;
		});
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
			if (event instanceof HttpResponse) {
				const headers = event.headers;
				if (this.loggedUser) {
					const sub = new SubscriptionHeader();
					sub.remainingdownload = headers.get(SubscriptionHeaderField.REMAINING_DOWNLOAD);
					sub.remainingsearches = headers.get(SubscriptionHeaderField.REMAINING_SEARCHES);
					if (headers.has(SubscriptionHeaderField.SUBSCRIPTION_AUTORENEW)) {
						sub.subscriptionautorenew = parseInt(headers.get(SubscriptionHeaderField.SUBSCRIPTION_AUTORENEW), 10);
					}
					if (headers.has(SubscriptionHeaderField.SUBSCRIPTION_ENDDATE)) {
						sub.subscriptionenddate = parseInt(headers.get(SubscriptionHeaderField.SUBSCRIPTION_ENDDATE), 10);
					}
					sub.subscriptiontype = headers.get(SubscriptionHeaderField.SUBSCRIPTION_TYPE);
					sub.subscriptionuilabel = headers.get(SubscriptionHeaderField.SUBSCRIPTION_UILABEL);
					this.sharedDataService.addCommonData('subscription', plainToClass(SubscriptionHeader, sub));
				} else {
					this.sharedDataService.addCommonData('subscription', undefined);
				}
			}
		}));
	}
}
