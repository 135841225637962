import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './shared/layout/layout.component';
import { UserGuardService } from './shared/user-guard.service';

const routes: Routes = [
	/*{
		path: 'accedi',
		component: LayoutComponent,
		children: [
			{
				path: '',
				redirectTo: 'home',
				pathMatch: 'full'
			},
			{
				path: 'home',
				loadChildren: './pages/home/home.module#HomeModule'
			},
			{
				path: 'login',
				loadChildren: './pages/accedi/accedi.module#AccediModule'
			},
		]
	},*/
	{
		path: '',
		component: LayoutComponent,
		children: [
			{
				path: '',
				redirectTo: 'home',
				pathMatch: 'full'
			},
			{
				path: 'home',
				loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule)
			},
			{
				path: 'offerte',
				loadChildren: () => import('./pages/offerte/offerte.module').then(m => m.OfferteModule)
			},
			{
				path: 'massimario',
				loadChildren: () => import('./pages/massimario/massimario.module').then(m => m.MassimarioModule)
			},
			{
				path: 'risultati',
				loadChildren: () => import('./pages/risultati/risultati.module').then(m => m.RisultatiModule)
			},
			{
				path: 'risultati-avanzati',
				loadChildren: () => import('./pages/risultati-avanzati/risultati-avanzati.module').then(m => m.RisultatiAvanzatiModule)
			},
			{
				path: 'singola',
				loadChildren: () => import('./pages/singola/singola.module').then(m => m.SingolaModule)
			},
			{
				path: 'massima/:url',
				loadChildren: () => import('./pages/massima/massima.module').then(m => m.MassimaModule)
			},
			{
				path: 'singola',
				loadChildren: () => import('./pages/singola/singola.module').then(m => m.SingolaModule)
			},
			{
				path: 'accedi',
				loadChildren: () => import('./pages/accedi/accedi.module').then(m => m.AccediModule)
			},
			{
				path: 'ricerca',
				loadChildren: () => import('./pages/ricerca/ricerca.module').then(m => m.RicercaModule)
			},
			{
				path: 'profile',
				loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule),
				data: {
					auth: true
				}
			},
			{
				path: 'checkout',
				loadChildren: () => import('./pages/checkout/checkout.module').then(m => m.CheckoutModule)
			},
			{
				path: 'registrationdone',
				loadChildren: () => import('./pages/registrationdone/registrationdone.module').then(m => m.RegistrationDoneModule)
			},
			{
				path: 'recupera-password',
				loadChildren: () => import('./pages/recupera-password/recupera-password.module').then(m => m.RecuperaPasswordModule)
			}
			,
			{
				path: 'static',
				loadChildren: () => import('./pages/static/static.module').then(m => m.StaticModule)
			}
		],
		canActivate: [
			UserGuardService
		],
		canActivateChild: [
			UserGuardService
		]
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
