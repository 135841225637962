import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User, UserService } from 'ricerca-amministrativa-ui-lib';
import { SharedDataService, AuthService } from 'elbuild-ui-lib-core';

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

	utente: User;
	showAccount: boolean = false;

	constructor(private router: Router,
		private sharedDataService: SharedDataService,
		private userService: UserService,
		private authService: AuthService) {
		this.sharedDataService.currentLoggedUser.subscribe((result: any) => {
			this.utente = result;
		});
	}

	ngOnInit(): void {

	}



	show(url: string, elementId: string): void {
		this.showAccount = false;
		this.router.navigateByUrl(url).then(() => document.getElementById(elementId).scrollIntoView());
	}

	toggleShowAccount(): void {
		this.showAccount = !this.showAccount;
	}

	logout() {
		this.userService.logout().subscribe(() => {
			this.authService.logout();
			this.router.navigateByUrl('/');
		});
	}

}
