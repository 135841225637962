<nav
	class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top"
	id="mainNav">
	<div class="container">
		<a
			class="navbar-brand js-scroll-trigger"
			(click)="show('/home', 'header')">
			<img
				class="logo"
				src="../../../../assets/imgs/logo.png">
		</a>
		<button
			class="navbar-toggler"
			type="button"
			data-toggle="collapse"
			data-target="#navbarResponsive"
			aria-controls="navbarResponsive"
			aria-expanded="false"
			aria-label="Toggle navigation">
			<i class="fas fa-bars"></i>
		</button>
		<div
			class="collapse navbar-collapse"
			id="navbarResponsive">
			<ul class="navbar-nav ml-auto">
				<!-- <li class="nav-item">
          <a class="nav-link js-scroll-trigger" href="/chi-siamo">Chi siamo</a>
        </li> -->
				<li class="nav-item">
					<a
						class="nav-link js-scroll-trigger"
						(click)="show('/static/come-funziona', 'contact')">
						{{ "labels.how-does-it-work" | translate}}
					</a>
				</li>
				<li class="nav-item">
					<a
						class="nav-link js-scroll-trigger"
						(click)="show('/offerte', 'header')">
						{{ "labels.prices-and-offers" | translate}}
					</a>
				</li>
				<li class="nav-item">
					<a
						class="nav-link js-scroll-trigger"
						(click)="show('/massimario', 'header')">
						{{ "labels.massimario" | translate}}
					</a>
				</li>
				<!-- <li class="nav-item">
          <a class="nav-link js-scroll-trigger" href="#services">Contatti</a>
        </li>
        <li class="nav-item">
          <a class="nav-link js-scroll-trigger" href="#services">Aiuto</a>
        </li> -->
				<li
					class="nav-item"
					*ngIf="!utente">
					<a
						class="nav-link login js-scroll-trigger"
						(click)="show('/accedi', 'header')">
						{{ "labels.login" | translate}}
					</a>
				</li>

				<li
					class="nav-item"
					*ngIf="utente">
					<div class="dropdown">
						<a
							class="dropdown-toggle nav-link login js-scroll-trigger"
							(click)="toggleShowAccount()">
							{{ "labels.hi" | translate}} {{utente.name}}
						</a>
						<div
							class="account-menu"
							[ngClass]="{'show': showAccount}">
							<a
								class="link-cerca dropdown-item"
								(click)="show('/profile/details', 'content')">
								{{ "labels.general-data" | translate}}
							</a>
							<a
								class="link-cerca dropdown-item"
								(click)="show('/profile/billing', 'content')">
								{{ "labels.billing-data" | translate}}
							</a>
							<a
								class="link-cerca dropdown-item"
								(click)="show('/profile/subscriptions', 'content')">
								{{ "labels.subscriptions" | translate}}
							</a>
							<a
								class="link-cerca dropdown-item"
								(click)="show('/profile/creditcards', 'content')">
								{{ "labels.cards" | translate}}
							</a>
							<a
								class="link-cerca dropdown-item"
								(click)="show('/profile/changepassword', 'content')">
								{{ "labels.change-password" | translate}}
							</a>
							<a
								class="link-cerca dropdown-item"
								(click)="show('/profile/orders', 'content')">
								Ordini
							</a>
							<!-- <a
								class="link-cerca dropdown-item"
								(click)="show('/profile/orders', 'content')">
								Fatture
							</a>
							 <a
								class="link-cerca dropdown-item"
								(click)="show('/profile', 'content')">
								Tutorial
							</a> -->
							<a
								class="link-cerca dropdown-item"
								(click)="show('/profile/wishlist', 'content')">
								Preferiti
							</a>
							<a
								class="link-cerca dropdown-item"
								(click)="show('/static/segnala-un-errore', 'content')">
								Supporto
							</a>
							<a
								class="link-cerca dropdown-item"
								(click)="logout()">
								{{ "labels.logout" | translate}}
							</a>

						</div>
					</div>
				</li>
			</ul>
		</div>
	</div>
</nav>
<div
	id="cover"
	[class.show]="showAccount"
	(click)="toggleShowAccount()">
</div>
