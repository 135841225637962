import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import localeItExtra from '@angular/common/locales/extra/it';
import { Router, NavigationEnd } from '@angular/router';




declare let ga: Function;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

	constructor(
		private translateService: TranslateService,
		private router: Router) {
		this.translateService.addLangs(['it']);
		this.translateService.setDefaultLang('it');
		this.translateService.use('it');
		// register locale for pipe
		registerLocaleData(localeIt, 'it', localeItExtra);
	}

	ngOnInit() {
		this.router.events.forEach((event) => {
			if (event instanceof NavigationEnd) {
				if (ga) {
					ga('set', 'page', event.urlAfterRedirects);
					ga('send', 'pageview');
				}
				if (event.url === '/login') {
					this.router.navigateByUrl('/signin');
				}
			}
		});
	}

}
