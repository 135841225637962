import 'reflect-metadata';

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutComponent } from './shared/layout/layout.component';
import { NavbarComponent } from './shared/layout/navbar/navbar.component';
import { RicercaAmministrativaUiLibModule } from 'ricerca-amministrativa-ui-lib';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthModule, ElbuildCoreComponentsModule, ElbuildUiLibModule, ElbuildCoreServicesModule, AuthConfig } from 'elbuild-ui-lib-core';
import { UserGuardService } from './shared/user-guard.service';
import { LoggedUserInterceptor } from './auth/logged-user.interceptor';

const authConfig: AuthConfig = {
	cookieprefix: 'ricAmmUI',
	usertable: 'user',
	loginpath: 'login',
	loginpage: 'accedi'/*,
	redirectunauthorized: false*/
};

export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
	declarations: [
		AppComponent,
		LayoutComponent,
		NavbarComponent
	],
	imports: [
		CommonModule,
		BrowserModule,
		BrowserAnimationsModule,
		FormsModule,
		HttpClientModule,
		ElbuildCoreComponentsModule,
		ElbuildUiLibModule.forRoot(authConfig),
		AuthModule,
		RicercaAmministrativaUiLibModule.forRoot(),
		AppRoutingModule,
		ToastrModule.forRoot({
			preventDuplicates: true
		}),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		})
	],
	providers: [
		ElbuildCoreServicesModule.forRoot().providers,
		UserGuardService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: LoggedUserInterceptor,
			multi: true
		}
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
