
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild } from '@angular/router';
import { Observable, Observer, of } from 'rxjs';
import { SharedDataService, Staff, AuthService } from 'elbuild-ui-lib-core';

@Injectable()
export class UserGuardService implements CanActivate, CanActivateChild {

	loggedUser: Staff;

	constructor(private router: Router,
		private authService: AuthService,
		private sharedDataService: SharedDataService) {
		this.sharedDataService.currentLoggedUser.subscribe(loggedUser => {
			this.loggedUser = loggedUser;
		});
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		const auth = route.data.auth;
		if (this.loggedUser) {
			return of(true);
		} else {
			if (this.authService.hasCookie()) {
				return Observable.create((observer: Observer<any>) => {
					this.authService.getLoggedUser().subscribe(() => {
						observer.next(true);
						observer.complete();
					}, () => {
						if (auth) {
							this.router.navigateByUrl('/');
							return;
						}
						observer.next(true);
						observer.complete();
					});
				});
			} else {
				if (auth) {
					this.router.navigateByUrl('/');
					return of(false);
				}
				return of(true);
			}
		}
	}

	canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		const auth = route.data.auth;
		if (auth && !this.loggedUser) {
			this.router.navigateByUrl('/');
			return of(false);
		}
		return of(true);
	}

}
